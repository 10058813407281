import React, { useEffect } from 'react';
import { Info, Square } from 'react-feather';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Button } from '@/components/Button/Button';
import { WithHoverTooltip } from '@/components/HoverTooltip/WithHoverTooltip';
import { useRecordingCalendarStore } from '@/components/Recording/hooks/useRecordingCalendarStore';
import { MAX_CALL_LENGTH_MIN, MIN_CALL_LENGTH_MIN } from '@/constants';
import { useInternetConnectionStatus } from '@/hooks/useInternetConnectionStatus';
import { useModal } from '@/hooks/useModal';
import { usePreventLeave } from '@/hooks/usePreventLeave';
import { useSubscription } from '@/hooks/useSubscription';
import { callDetailsActions } from '@/pages/CallDetailsPage/slice';
import { homeActions } from '@/pages/HomePage/slice';
import cn from '@/utils/cn';
import { formatDurationMinSecond } from '@/utils/formatDate';

import { DisplayRecordingCallDuration } from './components/DisplayRecordingCallDuration';
import { CallTooShortModal } from './modals/CallTooShortModal';
import { HeadphonesWarningModal } from './modals/HeadphonesWarningModal';
import { InternetConnectionLostModal } from './modals/InternetConnectionLostModal';
import { MicrophoneAccessErrorModal } from './modals/MicrophoneAccessErrorModal';
import { StopRecordingModal } from './modals/StopRecordingModal';
import { UnsupportedBrowserErrorModal } from './modals/UnsupportedBrowserErrorModal';
import { useCallRecordingStore } from './useCallRecordingStore';

export const RecordWidget: React.FC = () => {
  const {
    recordingCall,
    isRecordingRequested,
    isRecording,
    requestRecordingStart,
    showStopRecordingModal,
    requestRecordingStop,
    closeStopRecordingModal,
    stopRecording,
    isHeadphonesWarningAccepted,
    acceptHeadphonesWarning,
    recordingStartedAt,
    microphoneAccessError,
    unsupportedBrowserError,
    elapsedSeconds,
    isCallUploading,
    reset,
    saveCall,
  } = useCallRecordingStore();

  const { browserRecordingCall } = useRecordingCalendarStore();

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { isOffline } = useInternetConnectionStatus();
  const { setBrowserRecordingCall } = useRecordingCalendarStore();
  const { isOpen: isOfflineModalOpen, close: closeOfflineModal } = useModal(
    isOffline,
    { updateOnDefaultValueChange: true },
  );

  usePreventLeave(isRecording);

  const cancelRecording = () => {
    stopRecording();
    reset();
    navigate('/calls/');
  };

  const finishRecording = async () => {
    stopRecording();
    const newCall = await saveCall();
    reset();

    dispatch(homeActions.fetchCalls(null));
    dispatch(callDetailsActions.fetchCall({ id: (newCall as any).id }));
  };

  const secondsLeft = Number(MAX_CALL_LENGTH_MIN) * 60 - elapsedSeconds;

  // redirect to call details page on recording start
  useEffect(() => {
    if (!recordingCall) return;

    navigate(`/calls/${recordingCall.id}`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recordingCall]);

  useEffect(() => {
    if (secondsLeft < 0) {
      finishRecording();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [secondsLeft]);

  const { canCreateNewCalls, isPending: subscriptionLoading } =
    useSubscription();

  if (subscriptionLoading || !canCreateNewCalls) {
    return <></>;
  }

  if (!isRecordingRequested) {
    return <></>;
  }

  if (unsupportedBrowserError) {
    return <UnsupportedBrowserErrorModal show={true} onClose={reset} />;
  }

  if (!isHeadphonesWarningAccepted) {
    return (
      <HeadphonesWarningModal
        show={true}
        onClose={reset}
        onAccept={({ showAgain }) => {
          acceptHeadphonesWarning({ showAgain });
          requestRecordingStart(browserRecordingCall ?? undefined);
          setBrowserRecordingCall(null);
        }}
      />
    );
  }

  if (microphoneAccessError) {
    return <MicrophoneAccessErrorModal show={true} onClose={reset} />;
  }

  if (!isRecording || isCallUploading) {
    return <></>;
  }

  const getCallDurationSeconds = () => {
    return recordingStartedAt
      ? (new Date().getTime() - recordingStartedAt?.getTime()) / 1000
      : 0;
  };

  const showCountdown = secondsLeft < 5 * 60;
  const callIsTooShort =
    getCallDurationSeconds() < Number(MIN_CALL_LENGTH_MIN) * 60;

  return (
    <>
      {showStopRecordingModal && (
        <>
          <CallTooShortModal
            show={callIsTooShort}
            onClose={closeStopRecordingModal}
            onAccept={cancelRecording}
          />
          <StopRecordingModal
            show={!callIsTooShort}
            onClose={closeStopRecordingModal}
            onAccept={finishRecording}
            subtitle="Your call will be saved and analyzed"
          />
        </>
      )}
      <InternetConnectionLostModal
        show={isOfflineModalOpen}
        onClose={closeOfflineModal}
      />
      <div className="z-30 fixed left-0 bottom-8">
        <div
          className={cn(
            'flex items-center gap-2 pl-10 py-4 bg-dark transition-all',
            showCountdown
              ? 'w-[296px] rounded-tr-[20px]'
              : 'rounded-r-[20px] w-[205px]',
          )}
        >
          <WithHoverTooltip tooltip="Stop Recording" placement="bottom-start">
            <Button
              variant="danger"
              className="w-10 h-10 p-0 rounded-full"
              onClick={requestRecordingStop}
            >
              <Square size={20} />
            </Button>
          </WithHoverTooltip>

          <div className="flex flex-col justify-center item-start">
            <div className="font-bold text-base	text-main-gray">
              Recording...
            </div>
            <div className="font-normal text-sm	text-[#B9C7D7]">
              <DisplayRecordingCallDuration
                format="formatTimestamp"
                recordingType="user"
              />
            </div>
          </div>
        </div>
        <div
          className={cn(
            'w-full h-10 inline-flex items-center gap-1 text-gray-200 text-xs font-medium bg-[#252525] rounded-br-[20px] pl-10 py-3 transition-all',
            showCountdown ? 'visible opacity-100' : 'invisible opacity-0',
          )}
        >
          <img src="/hourglass-end-1.svg" className="h-3 w-3" alt="" />
          <span>
            Recording will stop in{' '}
            {formatDurationMinSecond(Math.max(secondsLeft, 0))}
          </span>
          <WithHoverTooltip
            tooltip="We only analyze calls up to 60min long"
            placement="right"
          >
            <Info size={12} className="cursor-pointer" />
          </WithHoverTooltip>
        </div>
      </div>
    </>
  );
};
