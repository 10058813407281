import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { DemoQualitativeFeedbackItems } from '@/components/CallDetailsPage/DemoCall/DemoCallMockData';
import { CollapsibleItem } from '@/components/CollapsibleItem/CollapsibleItem';
import { useCallDetailsSlice } from '@/pages/CallDetailsPage/slice';
import {
  selectCallQualitativeAnalysis,
  selectCallQualitativeAnalysisLoading,
} from '@/pages/CallDetailsPage/slice/selectors';
import { mapSpeakerletterToNumber } from '@/utils/speakerMapping';

import { Button } from '../Button/Button';
import { SectionTitle } from '../SectionTitle/SectionTitle';

const checkSpeakerNameAndMap = (text) => {
  if (!text) return '';
  const pattern = /Speaker\s[A-Za-z]/g;
  const matches = text.match(pattern);

  if (!matches) return text;

  let result = text;

  matches.forEach((match) => {
    const speakerLetter = match.split(' ')[1];
    const SpeakerNumber = mapSpeakerletterToNumber(speakerLetter);
    result = result.replace(match, `Speaker ${SpeakerNumber}`);
  });

  return result;
};

const toSentenceCase = (text) => {
  if (!text) return '';
  return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
};

const handleTextFormat = (text) => {
  if (!text) return '';
  let outputText = checkSpeakerNameAndMap(text);
  outputText = toSentenceCase(outputText);
  return outputText;
};

const PerformanceFeedback = ({ call }) => {
  let { id } = useParams();
  const dispatch = useDispatch();
  const [showFeedback, setShowFeedback] = useState(false);
  const [didWellText, setDidWellText] = useState([]);
  const [loading, setLoading] = useState(true);
  const [improvementsText, setImprovementsText] = useState([]);
  const [loadingIndexes, setLoadingIndexes] = useState([]);
  const callQualitativeAnalysisLoading = useSelector(
    selectCallQualitativeAnalysisLoading,
  );
  const callQualitativeAnalysis = useSelector(selectCallQualitativeAnalysis);
  const { actions: callDetailsActions } = useCallDetailsSlice();

  const qualitativeDataItems = call.isDemoCall
    ? DemoQualitativeFeedbackItems
    : callQualitativeAnalysis;

  const animateFeedbackItems = (items, setItems, callback) => {
    let currentIndex = 0;
    const feedbackTexts = [];

    if (items.length > 0) {
      feedbackTexts.push(items[0]);
      setItems([...feedbackTexts]);
      currentIndex = 1;
    }

    const interval = setInterval(() => {
      if (currentIndex < items.length) {
        setLoadingIndexes((prev) => [...prev, currentIndex]);

        feedbackTexts.push(items[currentIndex]);
        setItems([...feedbackTexts]);
        setLoadingIndexes((prev) => prev.filter((i) => i !== currentIndex));

        currentIndex += 1;
        setLoading(false);
      } else {
        clearInterval(interval);
        if (callback) callback();
      }
    }, 2000);
  };

  useEffect(() => {
    if (!callQualitativeAnalysisLoading && showFeedback) {
      const didWellItems = [];
      const needImprovementsItems = [];

      qualitativeDataItems?.forEach((el) => {
        switch (el.type) {
          case 'positive':
            didWellItems.push(el);
            break;
          case 'negative':
            needImprovementsItems.push(el);
            break;
        }
      });

      animateFeedbackItems(didWellItems, setDidWellText, () => {
        animateFeedbackItems(needImprovementsItems, setImprovementsText);
      });
    }
  }, [callQualitativeAnalysisLoading, showFeedback, qualitativeDataItems]);

  const handleGetFeedbackClick = async () => {
    dispatch(
      callDetailsActions.fetchCallQualitativeAnalysis({
        id,
      }),
    );
    setShowFeedback(true);
  };

  return (
    <div>
      <h2 className="hidden text-lg font-bold text-[#2d3648] mb-2">
        Performance Feedback
      </h2>
      <div>
        <SectionTitle className="mb-4">AI Coach feedback</SectionTitle>

        {!showFeedback && (
          <Button
            onClick={() => handleGetFeedbackClick()}
            className="py-2 px-4 bg-blue-500 text-white rounded-md"
          >
            Get feedback
          </Button>
        )}

        <div>
          <h3 className="text-base font-semibold mt-6">👍 What you did well</h3>
          <ul className="mt-4 flex flex-col gap-1.5">
            {!showFeedback && (
              <span className="text-sm text-[#5C6A82] mb-2">
                Select 'Get feedback' above to see what went well.
              </span>
            )}
            {didWellText.map((item, index) => (
              <li key={index}>
                <CollapsibleItem
                  title={handleTextFormat(item?.title)}
                  openByDefault
                  animation
                >
                  {checkSpeakerNameAndMap(item?.description)}
                </CollapsibleItem>
              </li>
            ))}
          </ul>

          <h3 className="text-base font-semibold mt-6">
            ⚡️ Where you can improve
          </h3>
          {!showFeedback && (
            <span className="text-sm text-[#5C6A82] mb-2">
              Select 'Get feedback' above to see improvement suggestions.
            </span>
          )}
          <ul className="mt-2.5 flex flex-col gap-1.5">
            {improvementsText.map((item, index) => (
              <li key={index}>
                <CollapsibleItem
                  title={handleTextFormat(item?.title)}
                  openByDefault
                  animation
                >
                  {checkSpeakerNameAndMap(item?.description)}
                </CollapsibleItem>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default PerformanceFeedback;
