import React, { useState, useEffect } from 'react';
import Collapsible from 'react-collapsible';
import { ChevronUp } from 'react-feather';
import { useModal } from '@/hooks/useModal';
import cn from '@/utils/cn';
import { ClipLoader } from 'react-spinners'; // Certifique-se de importar o ClipLoader

interface CollapsibleItemProps {
  title: React.ReactNode | ((params: { isOpen: boolean }) => React.ReactNode);
  children?: React.ReactNode;
  openByDefault?: boolean;
  hideDefaultChevron?: boolean;
  triggerDisabled?: boolean;
  animation?: boolean;
}

export const CollapsibleItem: React.FC<CollapsibleItemProps> = ({
  title,
  children,
  openByDefault,
  hideDefaultChevron,
  triggerDisabled,
  animation = false,
}) => {
  const { isOpen, open, close } = useModal(openByDefault);
  const [animatedText, setAnimatedText] = useState('');
  const [isTranscribing, setIsTranscribing] = useState(false);
  const [showLoader, setShowLoader] = useState(false);

  useEffect(() => {
    if (isOpen) {
      if (animation) {
        setShowLoader(true);
        const loaderTimeout = setTimeout(() => {
          setShowLoader(false);
          if (typeof children === 'string') {
            animateText(children);
          } else {
            setAnimatedText(children as string);
          }
        }, 2000);

        return () => clearTimeout(loaderTimeout);
      } else {
        setAnimatedText(children as string);
      }
    }

    return undefined;
  }, [isOpen, animation, children]);
  const animateText = (text: string) => {
    let index = 0;
    setAnimatedText('');
    setIsTranscribing(true);

    const interval = setInterval(() => {
      setAnimatedText((prev) => {
        const updatedText = prev + text[index];
        index += 1;
        if (index === text.length) {
          clearInterval(interval);
          setIsTranscribing(false);
        }
        return updatedText;
      });
    }, 5);
  };

  return (
    <div
      className={cn(
        'bg-white rounded-[20px] border border-main-gray py-4 px-5',
        triggerDisabled && 'pointer-events-none',
      )}
    >
      <Collapsible
        open={isOpen}
        triggerDisabled={triggerDisabled}
        onOpening={open}
        onClosing={close}
        trigger={
          <div className="flex gap-1 items-center text-sm font-semibold rounded-[20px] w-full">
            {!hideDefaultChevron && (
              <button className="text-accent-blue focus:outline-accent-blue">
                <ChevronUp
                  size={20}
                  className={cn('transition-transform', !isOpen && 'rotate-90')}
                />
              </button>
            )}
            {typeof title === 'function' ? title({ isOpen }) : title}
          </div>
        }
        transitionTime={100}
      >
        {children && (
          <div className="pt-1.5">
            {showLoader ? (
              <div className="flex items-center gap-2">
                <ClipLoader size={15} color="#000" />
                <span>Analyzing...</span>
              </div>
            ) : animation && isTranscribing ? (
              <p className="text-black text-sm">{animatedText}</p>
            ) : typeof children === 'string' ? (
              <p className="text-black text-sm">{children}</p>
            ) : (
              children
            )}
          </div>
        )}
      </Collapsible>
    </div>
  );
};
