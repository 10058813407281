const COLORS = [
  '#F57F17',
  '#AD1457',
  '#512DA8',
  '#2E7D32',
  '#37474F',
  '#D84315',
];

/**
 * Given a speaker number (index), map it to a color from the COLORS array
 * @param speakerNumber The index of the speaker
 * @returns HEX Code of the color to be used in the UI
 */
export function mapSpeakerNumberToColor(speakerNumber: number) {
  const colorIndex = (speakerNumber - 1) % COLORS.length; // Map the speaker number to a valid index in the COLORS array
  return COLORS[colorIndex];
}
