import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

import { RecordingSettingsModal } from '@/components/Calendar/RecordingSettings/RecordingSettingsModal';
import { HelpModal } from '@/components/HelpModal/HelpModal';
import { RevertImpersonationBanner } from '@/components/HomePage/RevertImpersonationBanner';
import { useQuickstartModalStore } from '@/components/Quickstart/hooks/useQuickstartModalStore';
import { QuickstartButton } from '@/components/Quickstart/QuickstartButton';
import { SubscribeModal } from '@/components/subscription/SubscribeModal/SubscribeModal';
import { useUploadModalStore } from '@/components/UploadCallModal/hooks/useUploadModalStore';
import { UploadCallModal } from '@/components/UploadCallModal/UploadCallModal';
import { selectUser } from '@/pages/HomePage/slice/selectors';
import { BotRecordWidget } from '@/widgets/RecordWidget/BotRecordWidget';

import Header from '../../components/Shared/Header';
import { RecordWidget } from '../../widgets/RecordWidget/RecordWidget';

// Page for logged in homepage
// Displays insights and calls tabs, and manages call upload
const HomePage = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const { isUploadModalOpen, closeUploadModal } = useUploadModalStore();
  const { showQuickstart, isQuickstartVisible } = useQuickstartModalStore();
  const user = useSelector(selectUser);

  useEffect(() => {
    if (!user) return;

    if (!user.quickStartDismissed) {
      showQuickstart();
    }
  }, [user]);

  useEffect(() => {
    if (location.pathname === '/') {
      navigate('/calls');
    }
  }, [location.pathname, navigate]);

  return (
    <div className="min-h-screen bg-white">
      <SubscribeModal />
      {isUploadModalOpen && (
        <UploadCallModal
          isOpen={isUploadModalOpen}
          onClose={closeUploadModal}
        />
      )}
      <RecordWidget />
      <BotRecordWidget />
      <HelpModal />
      <RecordingSettingsModal />
      {isQuickstartVisible && <QuickstartButton />}
      <div>
        <Header />
        <RevertImpersonationBanner />
        <Outlet />
      </div>
    </div>
  );
};

export default HomePage;
