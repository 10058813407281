import React, { useEffect, useRef, useState } from 'react';
import { ChevronDown, ChevronUp, Search } from 'react-feather';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { ClipLoader } from 'react-spinners';

import { Input } from '@/components/Input/Input';
import { useHomeSlice } from '@/pages/HomePage/slice';
import {
  selectCallTranscript,
  selectTranscriptLoading,
} from '@/pages/HomePage/slice/selectors';
import { formatTimestamp } from '@/utils/formatDate';
import { mapSpeakerletterToNumber } from '@/utils/speakerMapping';
import { validParamId } from '@/utils/validParamId';

import { Button } from '../Button/Button';
import { mapSpeakerNumberToColor } from './mapSpeakerNumberToColor';

const getStartEnd = (str, sub) => [
  str.indexOf(sub),
  str.indexOf(sub) + sub?.length + 1,
];

// Component for displaying transcript of calls
const CallTranscript = ({ call, currentAudioTime, selectedBlock }) => {
  let { id } = useParams();
  const dispatch = useDispatch();
  const { actions } = useHomeSlice();
  const callTranscript = useSelector(selectCallTranscript);
  const loading = useSelector(selectTranscriptLoading);

  const listRef = useRef(null);

  useEffect(() => {
    if (validParamId(id)) {
      dispatch(actions.fetchCallTranscript(id));
    }
  }, [id]);

  const [searchTerms, setSearchTerms] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [selectedSearchResultIndex, setSelectedSearchResultIndex] = useState(0);

  const speakersNameMap = Object.fromEntries(
    callTranscript?.nameMappings?.map(({ speakerCode, speakerName }) => [
      speakerCode,
      speakerName,
    ]) ?? [],
  );

  const formatTranscript = () => {
    const utterances = callTranscript?.utterances;
    if (!utterances) return [];

    const mappedUtterances = utterances.map((utterance) => {
      const [startIndex, endIndex] = getStartEnd(
        utterance.text,
        selectedBlock?.text,
      );

      let num = 0;

      return {
        active:
          utterance.startTimeInMs / 1000 <= currentAudioTime &&
          utterance.endTimeInMs / 1000 >= currentAudioTime,
        textContent: `${utterance.speaker}: ${utterance.words}`,
        content: utterance.words.map((w) => {
          num += w.text.length + 1;

          const selected =
            utterance.startTimeInMs === selectedBlock?.timestamp
              ? ((startIndex === 0 && num >= startIndex) || num > startIndex) &&
                num <= endIndex
              : false;

          return {
            content: w.text,
            done: w.endTimeInMs / 1000 < currentAudioTime,
            selected,
          };
        }),
        start: utterance.startTimeInMs,
        done: utterance.endTimeInMs / 1000 < currentAudioTime,
        selected: utterance.startTimeInMs === selectedBlock?.timestamp,
      };
    });

    if (searchTerms) {
      const searchTermsWords = searchTerms
        .split(' ')
        .map((w) => w.toLowerCase().trim())
        .filter((w) => w);

      let highlighIndex = 0;

      //Perform word sequential search

      mappedUtterances.forEach((utterance) => {
        utterance.content.forEach((word, wordIndex) => {
          const wordContent = word.content.toLowerCase();
          const index = wordContent.indexOf(searchTermsWords[0]);

          if (index !== -1) {
            let match = true;
            for (let j = 1; j < searchTermsWords.length; j++) {
              const nextWordObj = utterance.content[wordIndex + j];
              if (!nextWordObj) {
                match = false;
                break;
              }

              const nextWordContent = nextWordObj.content.toLowerCase();
              if (nextWordContent.indexOf(searchTermsWords[j]) === -1) {
                match = false;
                break;
              }
            }
            if (match) {
              for (let j = 0; j < searchTermsWords.length; j++) {
                utterance.content[wordIndex + j].highlight = true;
                utterance.content[wordIndex + j].highlightIndex = highlighIndex;
              }
              highlighIndex++;
            }
          }
        });
      });
    }

    return mappedUtterances;
  };

  let transcriptText = formatTranscript();

  useEffect(() => {
    if (!searchTerms) {
      setSearchResults([]);
      return;
    }

    const singleString = transcriptText
      .map((interaction) =>
        interaction.content.map((word) => word.content).join(' '),
      )
      .join(' ');

    const regex = new RegExp(searchTerms, 'gi');
    const results = [];

    let match;
    while ((match = regex.exec(singleString))) {
      results.push(match.index);
    }

    const firstElement = listRef.current?.querySelector(
      `[data-highlight-index="${selectedSearchResultIndex}"]`,
    );
    if (firstElement) {
      firstElement.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    }

    setSearchResults(results);
    setSelectedSearchResultIndex(0);
  }, [searchTerms]);

  useEffect(() => {
    if (!selectedBlock || loading || currentAudioTime) return;

    const timer = setTimeout(() => {
      listRef.current?.querySelector('#selected')?.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    }, 250);

    return () => {
      clearTimeout(timer);
    };
  }, [selectedBlock, loading, currentAudioTime]);

  useEffect(() => {
    const firstElement = listRef.current?.querySelector(
      `[data-highlight-index="${selectedSearchResultIndex}"]`,
    );
    if (firstElement) {
      firstElement.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    }
  }, [selectedSearchResultIndex]);

  if (loading) {
    return (
      <div className="flex justify-center">
        <ClipLoader color="rgb(37 99 235)" />
      </div>
    );
  }

  return (
    <div className="flex flex-col">
      <div className="flex border-b p-[16px] gap-[8px] items-center sticky top-[-48px] bg-white rounded-tr-2xl rounded-tl-2xl">
        <Input
          placeholder="Search Transcript"
          leftSlot={<Search size={20} />}
          value={searchTerms}
          onChange={(e) => {
            setSearchTerms(e.target.value);
          }}
          onKeyDown={
            searchTerms
              ? (e) => {
                  if (e.key === 'Enter') {
                    setSelectedSearchResultIndex((prev) =>
                      searchResults.length > 0
                        ? (prev + 1) % searchResults.length
                        : 0,
                    );
                  }
                }
              : undefined
          }
          className="flex-1 transition-all"
        />

        {searchTerms && (
          <>
            <div className="flex flex-row gap-3 flex-shrink-0 items-center w-42 justify-center">
              {searchResults.length > 0 ? (
                <>
                  <span className="text-sm">
                    {selectedSearchResultIndex + 1} of {searchResults.length}
                  </span>
                  <div
                    className="inline-flex rounded-md shadow-sm"
                    role="group"
                  >
                    <Button
                      variant="secondary"
                      className="!rounded-tr-none !rounded-br-none !p-2"
                      onClick={() =>
                        setSelectedSearchResultIndex((prev) => {
                          if (prev === 0) return searchResults.length - 1;
                          return prev - 1;
                        })
                      }
                    >
                      <ChevronUp size={16} />
                    </Button>
                    <Button
                      className="!rounded-tl-none !rounded-bl-none !p-2"
                      variant="secondary"
                      onClick={() =>
                        setSelectedSearchResultIndex(
                          (prev) => (prev + 1) % searchResults.length,
                        )
                      }
                    >
                      <ChevronDown size={16} />
                    </Button>
                  </div>
                </>
              ) : (
                <span className="text-[#2C6CF6] text-sm">
                  {searchResults.length} results found
                </span>
              )}
            </div>
            <div>
              <Button onClick={() => setSearchTerms('')} variant="secondary">
                Cancel
              </Button>
            </div>
          </>
        )}
      </div>

      <div className="p-6">
        {callTranscript && (
          <div ref={listRef} id="transcript-content">
            {transcriptText.map((text, index) => {
              const textSpeaker = text?.textContent.split(':')[0];
              const speakerName = speakersNameMap[textSpeaker];
              return (
                <div
                  key={index}
                  className="mb-4"
                  id={text.selected ? 'selected' : ''}
                >
                  <div>
                    <div className="flex items-center gap-1 mb-1">
                      <div
                        className="rounded-full w-2 h-2"
                        style={{
                          backgroundColor:
                            textSpeaker === call.mainSpeaker
                              ? '#1774F8'
                              : mapSpeakerNumberToColor(
                                  mapSpeakerletterToNumber(textSpeaker),
                                ),
                        }}
                      ></div>
                      <p className="text-sm font-semibold">
                        {speakerName
                          ? speakerName
                          : `Speaker ${mapSpeakerletterToNumber(textSpeaker)}`}

                        {call.mainSpeaker === textSpeaker && ' (Seller)'}
                      </p>
                      <span className="ml-1.5 text-[#717D96] text-sm">
                        {formatTimestamp(text.start)}
                      </span>
                    </div>
                    <p
                      className={`${text.active ? 'bg-[#1774F80F] rounded-l p-[4px] text-black' : ''} ${
                        text.selected
                          ? 'bg-[#1774F80F] rounded-[10px] py-1 px-2'
                          : ''
                      }`}
                    >
                      {text?.content.map((w, index) => (
                        <span
                          key={index}
                          className={`${w.done ? 'font-semibold' : ''} ${w.selected ? 'text-[#2C6CF6]' : ''}`}
                        >
                          {w.highlight ? (
                            <>
                              <span
                                className={`${w.highlightIndex === selectedSearchResultIndex ? 'bg-[#F89B11]/60 font-bold' : 'bg-[#F89B11]/20'}`}
                                data-highlight-index={w.highlightIndex}
                              >
                                {w?.content}
                              </span>{' '}
                            </>
                          ) : (
                            <> {w?.content} </>
                          )}
                        </span>
                      ))}
                    </p>
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
};

export default CallTranscript;
