import dayjs from 'dayjs';
import dayjsUtcPlugin from 'dayjs/plugin/utc';
dayjs.extend(dayjsUtcPlugin);

import React from 'react';
import { Check, X } from 'react-feather';
import { Link } from 'react-router-dom';

import {
  InsightsAcrossCallChartRawDataItem,
  InsightsAcrossCallChartRawDataItemDetails,
} from '@/components/InsightsAcrossCallsPage/chart/InsightsAcrossCallsChart';
import { useInsightsAcrossCallsStore } from '@/components/InsightsAcrossCallsPage/hooks/useInsightsAcrossCallsStore';
import { InsightsAcrossCallsTimeRangeOptionsValue } from '@/components/InsightsAcrossCallsPage/InsightsAcrossCallsTimeRangeDropdown';
import { MetricEvaluationDataMapper } from '@/components/InsightsAcrossCallsPage/mappers/MetricEvaluationDataMapper';
import { PlaybookEvaluationDataMapper } from '@/components/InsightsAcrossCallsPage/mappers/PlaybookEvaluationDataMapper';
import { ProspectEvaluationDataMapper } from '@/components/InsightsAcrossCallsPage/mappers/ProspectEvaluationDataMapper';
import {
  BasicMetricsEvaluationHelper,
  PossibleMetrics,
} from '@/components/Shared/helpers/BasicMetricsEvaluationHelper';
import cn from '@/utils/cn';

import { Button } from '../Button/Button';

const QuestionMark = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
  >
    <path
      d="M3 3.50298C3.24196 2.83515 3.71954 2.27201 4.34815 1.9133C4.97676 1.55459 5.71584 1.42347 6.43448 1.54316C7.15313 1.66284 7.80495 2.02561 8.27452 2.56721C8.74408 3.10881 9.00108 3.79429 9 4.50224C9 6.50075 5.91252 7.5 5.91252 7.5"
      stroke="#BE7200"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6 9.75V10.5H6.005"
      stroke="#BE7200"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export enum PersonalInsightsChartViewType {
  Playbook = 'playbook',
  Prospect = 'prospect',
  Metrics = 'metrics',
}

interface PersonalInsightsChartTooltipProps {
  type: PersonalInsightsChartViewType;
  selectedMetric: string;
  data: InsightsAcrossCallChartRawDataItem;
}

export const PersonalInsightsChartTooltip: React.FC<
  PersonalInsightsChartTooltipProps
> = ({ selectedMetric, data, type }: PersonalInsightsChartTooltipProps) => {
  const { selectedDateOption } = useInsightsAcrossCallsStore();

  if (!data || !selectedMetric) return <></>;

  const showBooleanView =
    type === PersonalInsightsChartViewType.Prospect ||
    (type === PersonalInsightsChartViewType.Playbook &&
      selectedMetric !== 'playbookFulfilledPercentage');

  const mapColorValue = (value: number): string => {
    switch (type) {
      case PersonalInsightsChartViewType.Playbook:
        return PlaybookEvaluationDataMapper.mapColor(value);
      case PersonalInsightsChartViewType.Metrics:
        return BasicMetricsEvaluationHelper.mapColorByMetricKey[
          selectedMetric as PossibleMetrics
        ](value);
      case PersonalInsightsChartViewType.Prospect:
        return ProspectEvaluationDataMapper.mapColor(value);
    }
  };

  const mapValue = (value: number): string => {
    switch (type) {
      case PersonalInsightsChartViewType.Playbook:
        return PlaybookEvaluationDataMapper.getValueMapper(selectedMetric)(
          value,
        );
      case PersonalInsightsChartViewType.Metrics:
        return MetricEvaluationDataMapper.getValueMapper(
          selectedMetric as PossibleMetrics,
        )(value);
      case PersonalInsightsChartViewType.Prospect:
        return ProspectEvaluationDataMapper.getValueMapper(selectedMetric)(
          value,
        );
    }
  };

  const callsAverageValue = (
    calls: InsightsAcrossCallChartRawDataItemDetails[],
  ): number => {
    return Math.round(
      calls.reduce((accum, item) => accum + item.value, 0) / calls.length,
    );
  };

  const groupedCalls = data.items.reduce(
    (
      accum: { [key in string]: InsightsAcrossCallChartRawDataItemDetails[] },
      item: InsightsAcrossCallChartRawDataItemDetails,
    ) => {
      // Remove the time from the date, only consider the Day, Month and year
      const callDate = dayjs.utc(item.date).format('YYYY-MM-DD');

      const dateItems: InsightsAcrossCallChartRawDataItemDetails[] =
        accum[callDate];

      if (dateItems?.length) {
        dateItems.push(item);
        accum[callDate] = dateItems;
      } else {
        accum[callDate] = [item];
      }

      return accum;
    },
    {},
  );
  const isSingleCallForTimeRange =
    Object.keys(groupedCalls).length === 1 &&
    Object.values(groupedCalls)[0]?.length === 1;

  const formatListDateHeader = (date: string) => {
    let startDate: string;
    let endDate: string;

    if (isSingleCallForTimeRange) {
      const dateRangeCalls = Object.values(groupedCalls)[0];

      return dayjs.utc(dateRangeCalls[0].date).format('MMM D');
    }

    switch (selectedDateOption?.value) {
      case InsightsAcrossCallsTimeRangeOptionsValue.Month:
        startDate = dayjs.utc(date).format('MMM D');
        endDate = dayjs.utc(date).add(6, 'days').format('MMM D');

        return `${startDate} - ${endDate}`;
      case InsightsAcrossCallsTimeRangeOptionsValue.ThreeMonths:
        startDate = dayjs.utc(date).format('MMM D');
        endDate = dayjs.utc(date).endOf('month').format('MMM D');

        return `${startDate} - ${endDate}`;
      case InsightsAcrossCallsTimeRangeOptionsValue.TwoWeeks:
      case InsightsAcrossCallsTimeRangeOptionsValue.Week:
      default:
        return dayjs.utc(date).format('MMM D');
    }
  };

  const displayResult = (value: number): React.ReactNode => {
    if (!showBooleanView) return mapValue(value);

    if (value === 100) {
      return <Check size={12} />;
    }

    if (value === 0) {
      return <X size={12} />;
    }

    return QuestionMark;
  };

  return (
    <div className="overflow-y-auto max-h-[280px] max-w-[272px] p-3 text-start">
      <p className="text-dark text-[10px] mb-3 leading-normal tracking-[-0.1px] font-semibold">
        <span>{formatListDateHeader(data.dateGroupKey)}:</span>
        <span
          className="ml-1"
          style={{
            color: mapColorValue(callsAverageValue(data.items)),
          }}
        >
          {mapValue(callsAverageValue(data.items))} avg.
        </span>
      </p>
      {Object.entries(groupedCalls).map(([date, calls], groupIndex) => {
        const groupsAmount = Object.keys(groupedCalls).length;

        return (
          <div key={date}>
            {groupsAmount > 1 && (
              <div
                className={cn(
                  'text-[#5C6A82] text-[10px] mb-2 leading-[15px] tracking-[-0.1px]',
                  isSingleCallForTimeRange && 'text-dark font-semibold',
                )}
              >
                <span>{dayjs.utc(date).format('MMM D')}</span>
                <span
                  className="ml-1 font-semibold"
                  style={{
                    color: mapColorValue(callsAverageValue(calls)),
                  }}
                >
                  {mapValue(callsAverageValue(calls))} avg.
                </span>
              </div>
            )}
            {calls.map((call, callIndex) => (
              <div key={call.callId}>
                <div
                  className={cn(
                    'flex flex-row items-center',
                    callIndex !== 0 && 'mt-2',
                  )}
                  style={{
                    textWrap: 'nowrap', // tailwind text-nowrap not working
                  }}
                >
                  <span
                    className={cn(
                      'rounded-lg font-medium text-[10px] mr-1 flex items-center leading-normal',
                      showBooleanView ? 'p-1' : 'px-[6px] py-[3px]',
                    )}
                    style={{
                      color: mapColorValue(call.value),
                      background: mapColorValue(call.value) + '29', // 0.16 opacity in HEX = 29,
                    }}
                  >
                    {displayResult(call.value)}
                  </span>
                  <Link
                    to={`/calls/${call.callId}/`}
                    className="text-dark hover:text-accent-blue active:text-accent-blue focus:text-accent-blue duration-75 text-sm font-normal overflow-ellipsis overflow-hidden whitespace-nowrap w-full"
                  >
                    {call.name}
                  </Link>
                </div>
              </div>
            ))}
            {groupsAmount > 1 && groupIndex < groupsAmount - 1 && (
              <div className="w-full h-[1px] my-2.5 bg-[#ECECEC]"></div>
            )}
          </div>
        );
      })}
    </div>
  );
};
