import { Switch } from '@headlessui/react';

import cn from '@/utils/cn';

export interface ToggleProps {
  checked: boolean;
  onChange?: () => void;
  className?: string;
}

export const Toggle: React.FC<ToggleProps> = ({
  checked,
  onChange,
  className,
}) => {
  return (
    <Switch.Group>
      <div className={cn('flex items-center justify-between', className)}>
        <Switch
          checked={checked}
          onChange={onChange}
          className={cn(
            'relative inline-flex h-4 w-7 items-center rounded-full transition-colors focus:outline-none focus:ring-0',
            checked ? 'bg-accent-blue' : 'bg-main-gray',
          )}
        >
          <span
            className={`${
              checked ? 'translate-x-3.5' : 'translate-x-0.5'
            } inline-block h-3 w-3 transform rounded-full bg-white transition-transform`}
          />
        </Switch>
      </div>
    </Switch.Group>
  );
};
