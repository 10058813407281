import React, { useState } from 'react';
import { Check, ChevronDown, Edit } from 'react-feather';
import { useSelector } from 'react-redux';

import { eventTracker } from '@/amplitude/eventTracker';
import { Button } from '@/components/Button/Button';

import { selectCall } from '../../pages/CallDetailsPage/slice/selectors';
import { selectCallTranscript } from '../../pages/HomePage/slice/selectors';
import { getSpeakerQuotes } from '../../utils/getSpeakerQuotes';
import { mapSpeakerletterToNumber } from '../../utils/speakerMapping';
import { Input } from '../Input/Input';

export const SpeakerSelection = ({ updateCall, setSpeakerName }) => {
  let [expanded, setExpanded] = useState(null);
  const callTranscript = useSelector(selectCallTranscript);
  const speakerQuotes = getSpeakerQuotes(callTranscript);
  const speakers = Object.keys(speakerQuotes);
  const call = useSelector(selectCall);

  const [isEditing, setIsEditing] = useState(false);
  const [editedSpeaker, setEditedSpeaker] = useState(null);
  const [editedSpeakerName, setEditedSpeakerName] = useState('');

  const handleEditSpeaker = (speaker) => {
    setIsEditing(true);
    setEditedSpeaker(speaker);
  };

  const handleCancelEdit = () => {
    setIsEditing(false);
    setEditedSpeaker(null);
    setEditedSpeakerName('');
  };

  const handleSaveSpeakerName = () => {
    eventTracker.callDetails.changeSellerName({
      callId: call.id,
      name: editedSpeakerName,
      sellerIdentifier: editedSpeaker,
    });

    setSpeakerName(editedSpeaker, editedSpeakerName);

    setIsEditing(false);
    setEditedSpeaker(null);
    setEditedSpeakerName('');
  };

  const getQuotesToShow = (speaker) => {
    if (speaker === expanded) {
      return speakerQuotes[speaker]?.slice(0, 10);
    } else {
      return speakerQuotes[speaker]?.slice(0, 3);
    }
  };

  const speakersNameMap = Object.fromEntries(
    callTranscript?.nameMappings?.map(({ speakerCode, speakerName }) => [
      speakerCode,
      speakerName,
    ]) ?? [],
  );

  return (
    <div className="flex flex-col gap-4">
      <div>
        <label
          htmlFor="date"
          className="block text-base text-dark font-extrabold"
        >
          Which speaker is the seller?
        </label>
        <span className="text-xs text-main-gray-dark font-normal">
          Tell us whose performance you want to analyze
        </span>
      </div>
      <div className="flex flex-col gap-2">
        {speakers?.map((speaker) => {
          const speakerName = speakersNameMap[speaker];

          return (
            <div
              className="flex flex-col gap-[10px] rounded-[20px] border-[1px] border-solid border-main-gray w-[744px] px-5 py-4"
              key={speaker}
            >
              <div className="flex items-start self-stretch">
                <div className="w-full flex flex-col gap-2">
                  <div className="flex flex-row gap-1">
                    {!(isEditing && editedSpeaker === speaker) ? (
                      <>
                        <p className="text-dark font-semibold text-base">
                          {speakerName ??
                            `Speaker ${mapSpeakerletterToNumber(speaker)}`}
                        </p>
                        {!speakerName && (
                          <Button
                            variant="link"
                            size="sm"
                            onClick={() => handleEditSpeaker(speaker)}
                          >
                            <Edit size={16} />
                          </Button>
                        )}
                      </>
                    ) : (
                      <div className="flex flex-row gap-2 items-center">
                        <Input
                          value={editedSpeakerName}
                          onChange={(e) => setEditedSpeakerName(e.target.value)}
                          placeholder="Enter speaker name"
                        />

                        <Button
                          variant="primary"
                          onClick={handleSaveSpeakerName}
                          type="button"
                        >
                          Save
                        </Button>

                        <Button
                          type="button"
                          variant="secondary"
                          onClick={handleCancelEdit}
                        >
                          Cancel
                        </Button>
                      </div>
                    )}
                  </div>

                  <div className="text-main-gray-dark text-xs">Quotes:</div>
                </div>
                {speaker === call?.mainSpeaker ? (
                  <Button variant="primary">
                    <Check size={16} />
                    Selected
                  </Button>
                ) : (
                  <Button
                    variant="secondary"
                    onClick={() => {
                      eventTracker.callDetails.changeSellerApply({
                        callId: call.id,
                        speaker,
                      });
                      updateCall(
                        {
                          mainSpeaker: speaker,
                        },
                        'speaker',
                      );
                    }}
                  >
                    Select
                  </Button>
                )}
              </div>
              <div>
                {
                  <ul className="list-inside list-disc text-sm text-dark">
                    {getQuotesToShow(speaker).map((q) => (
                      <li key={q}>{q}</li>
                    ))}
                  </ul>
                }
                {speaker !== expanded && (
                  <button
                    className="flex items-center gap-1 mt-1.5 cursor-pointer"
                    onClick={(e) => {
                      e.stopPropagation();
                      setExpanded(speaker);
                    }}
                    type="button"
                  >
                    <ChevronDown size={16} />{' '}
                    <span className="text-sm font-semibold">More</span>
                  </button>
                )}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
