import { Dialog, Transition } from '@headlessui/react';
import { Fragment } from 'react';
import { X } from 'react-feather';
import { useDispatch, useSelector } from 'react-redux';

import { useCallDetailsSlice } from '../../pages/CallDetailsPage/slice';
import {
  selectCall,
  selectChangeSpeakerModalOpen,
} from '../../pages/CallDetailsPage/slice/selectors';
import { useHomeSlice } from '../../pages/HomePage/slice';
import { SpeakerSelection } from '../CallDetailsPage/SpeakerSelection';

export const ChangeSpeakerModal = () => {
  const dispatch = useDispatch();
  const { actions } = useHomeSlice();
  const { actions: callDetailsActions } = useCallDetailsSlice();
  const changeSpeakerModalOpen = useSelector(selectChangeSpeakerModalOpen);
  const call = useSelector(selectCall);

  const updateCall = (body) => {
    dispatch(
      actions.updateCall({
        id: call.id,
        body,
      }),
    );

    dispatch(callDetailsActions.fetchCall({ id: call.id }));
  };

  const changeSpeaker = (speaker) => {
    updateCall({
      mainSpeaker: speaker,
    });
  };

  const handleSpeakerChange = (speaker) => {
    changeSpeaker(speaker?.mainSpeaker);
    dispatch(callDetailsActions.closeChangeSpeakerModal());
  };

  /**
   * Sets the name of a call speaker
   * @param {*} speakerId - The ID of the speaker, its a single letter (A,B,C)
   * @param {*} speakerName - The name of the speaker to set
   */
  const handleSetSpeakerName = async (speakerId, speakerName) => {
    dispatch(
      actions.updateTranscript({
        id: call.id,
        body: {
          speakerNames: [
            {
              speakerId,
              speakerName,
            },
          ],
        },
      }),
    );

    dispatch(callDetailsActions.closeChangeSpeakerModal());
  };

  return (
    <Transition appear show={changeSpeakerModalOpen} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-30"
        onClose={() => {
          dispatch(callDetailsActions.closeChangeSpeakerModal());
        }}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="transform rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all overflow-visible">
                <div>
                  <X
                    size={24}
                    color="#5C6A82"
                    className="absolute right-3 top-3 cursor-pointer"
                    onClick={() =>
                      dispatch(callDetailsActions.closeChangeSpeakerModal())
                    }
                  />
                </div>
                <SpeakerSelection
                  updateCall={handleSpeakerChange}
                  setSpeakerName={handleSetSpeakerName}
                />
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};
