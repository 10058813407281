import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { eventTracker } from '@/amplitude/eventTracker';

import { useHomeSlice } from '../../pages/HomePage/slice';
import {
  selectLoggedIn,
  selectLoginError,
} from '../../pages/HomePage/slice/selectors';
import { useSignUpSlice } from '../../pages/SignUpPage/slice';
import {
  selectLoading,
  selectVerifiedEmail,
} from '../../pages/SignUpPage/slice/selectors';
import { Button } from '../Button/Button';
import { EmailInput } from '../Input/EmailInput';
import { PasswordInput } from '../Input/PasswordInput';
import { Label } from '../Label/Label';

export const SignInForm: React.FC = () => {
  const dispatch = useDispatch();
  const { actions } = useSignUpSlice();
  const { actions: homeActions } = useHomeSlice();

  const navigate = useNavigate();

  const loggedIn = useSelector(selectLoggedIn);
  const loginErrorMessage = useSelector(selectLoginError);
  const loading = useSelector(selectLoading);
  const verifiedEmail = useSelector(selectVerifiedEmail);

  const [password, setPassword] = useState('');

  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (loginErrorMessage) {
      dispatch(homeActions.resetLoginError(null));
    }
    setPassword(event.target.value);
  };

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(actions.setEmailNotUsed(null));
    dispatch(actions.setVerifiedEmail(event.target.value));
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    login();
  };

  const login = () => {
    dispatch(homeActions.login({ verifiedEmail, password }));
  };

  const toResetPassword = () => {
    eventTracker.auth.passwordResetClick({ email: verifiedEmail });
    navigate('/reset-password', { state: { email: verifiedEmail } });
  };

  const toSignUp = () => {
    dispatch(actions.setEmailNotUsed(null));
    navigate('/auth/register');
  };

  useEffect(() => {
    return () => {
      setPassword('');
    };
  }, []);

  useEffect(() => {
    if (loggedIn) {
      navigate('/');
    }
  }, [loggedIn]);

  useEffect(() => {
    dispatch(homeActions.resetLoginError(null));
  }, [verifiedEmail, password]);

  return (
    <form onSubmit={handleSubmit} className="w-[400px]">
      {loginErrorMessage && (
        <div className="flex flex-col mb-6">
          <span className="text-[#E74029] text-sm font-bold">
            Wrong password, please try again
          </span>
          <Button variant="link" onClick={toResetPassword} className="p-0">
            Forgot Password?
          </Button>
        </div>
      )}

      <div className="mb-4">
        <Label htmlFor="email">Work email address</Label>
        <EmailInput
          id="email"
          name="email"
          placeholder="Enter your work email"
          value={verifiedEmail}
          onChange={handleEmailChange}
          required
        />
      </div>

      <div className="mb-4">
        <Label htmlFor="password" isError={loginErrorMessage}>
          Password
        </Label>
        <PasswordInput
          id="password"
          name="password"
          placeholder="Enter your password"
          value={password}
          onChange={handlePasswordChange}
          required
          isError={loginErrorMessage}
        />
        <div className="flex justify-end">
          <Button
            onClick={toResetPassword}
            className="text-[10px] font-bold"
            variant="link"
          >
            Forgot password?
          </Button>
        </div>
      </div>

      <div className="flex flex-col items-center">
        <Button
          className="w-full"
          type="submit"
          disabled={!verifiedEmail || loading}
        >
          Sign in
        </Button>
        <div className="flex flex-row items-center mt-3 self-start">
          <span className="text-dark font-medium text-sm">
            Don&apos;t have an account yet?
          </span>
          <Button variant="link" onClick={toSignUp}>
            Sign up
          </Button>
        </div>
      </div>
    </form>
  );
};
